import * as React from "react"
import { Accordion } from "./accordion"


export const Faq = () => {
  const config  = [
    {
      title:' Для чего нужен дизайн проект?',
      text:'<p>Для того чтобы сделать дизайн проект дома, требуется больше компетенций и&nbsp;опыта, чем для разработки <a href="/uslugi/dizajn-interera" target="_blank">дизайн проекта квартиры</a>. Наличие архитектурного образования у&nbsp;дизайнера в&nbsp;легких случаях приветствуется, а&nbsp;в&nbsp;трудных&nbsp;&mdash; требуется.</p><p>В&nbsp;состав дизайн проекта входят все документы, позволяющие построить новый интерьер в&nbsp;вашем доме.</p>'
    },    {
      title:' Сколько времени вы&nbsp;делаете дизайн-проект дома?',
      text:'<p>Около 90&nbsp;дней занимает разработка дизайн-проекта дома. На&nbsp;сроки проектирования может влиять размер дома и&nbsp;его состояние.</p>'
    },    {
      title:' Будет&nbsp;ли учтен бюджет при разработке дизайн проекта?',
      text:'<p>Мы&nbsp;можем точно рассчитать все затраты на&nbsp;строительные работы и&nbsp;черновые материалы. Затраты&nbsp;же на&nbsp;чистовые материалы, мебель и&nbsp;оборудование удобнее подсчитывать в&nbsp;процессе комплектации, и&nbsp;эта услуга у&nbsp;нас совмещена с&nbsp;услугой авторского сопровождения.</p>'
    },    {
      title:' В&nbsp;каком стиле дизайна вы&nbsp;работаете?',
      text:'<p>Работаем во&nbsp;всех стилистических направлениях, ориентируемся на&nbsp;пожелания наших клиентов.</p>'
    },    {
      title:' Занимаетесь&nbsp;ли вы&nbsp;дизайном фасадов и&nbsp;ландшафтным дизайном?',
      text:'<p>Да, занимаемся. Мы&nbsp;делаем проекты модернизации и&nbsp;реконструкции фасадов, занимаемся ландшафтным дизайном совместно с&nbsp;подрядчиками и&nbsp;делаем индивидуальные архитектурные проекты домов с&nbsp;чистого листа.</p>'
    },    {
      title:' Как оплачивается дизайн-проект?',
      text:'<p>Дизайн-проект оплачивается в&nbsp;4&nbsp;этапа по&nbsp;25% от&nbsp;стоимости, наличными или переводом на&nbsp;расчетный счет.</p>'
    },    {
      title:' Сколько времени занимает строительство дома?',
      text:'<p>В&nbsp;среднем, строительство дома занимает около года. Еще около года потребуется на&nbsp;обустройство интерьера дома и&nbsp;прилегающей территории.</p>'
    },    {
      title:' Что такое автоматизация дома, «умный дом»?',
      text:'<p>Понятие современный дом тесно связано с&nbsp;автоматизацией систем контроля климата, освещения и&nbsp;безопасности. Все управление дублируется на&nbsp;смартфон/планшет/компьютер, что очень удобно и&nbsp;позволяет сэкономить время.</p>'
    },    {
      title:' Что входит в систему «умный дом»?',
      text:'<p>Система управления климатом, которая регулирует отопление (включая радиаторы, внутрипольные конвекторы и&nbsp;подогрев пола), кондиционирование, вентиляцию. Все функционирует на&nbsp;основании показаний климатических датчиков, а&nbsp;также настроенных пользовательских режимов, с&nbsp;учетом поры года, времени суток, распорядка дня.</p><p>Система управления освещением регулируют работу светильников внутри и&nbsp;снаружи дома. Программируются определенные сценарии, зависящие от&nbsp;времени суток, или атмосферы, которую необходимо создать. Датчики присутствия в&nbsp;определенных местах позволяют рационально тратить электроэнергию, не&nbsp;задумываясь при этом&nbsp;&mdash; какую клавишу на&nbsp;выключателе нужно нажимать.</p><p>Системы безопасности и&nbsp;охранные сигнализации. Понятие безопасности в&nbsp;&laquo;умном доме&raquo; имеет более широкий смысл и&nbsp;помогает защитить здание не&nbsp;только от&nbsp;нежелательного проникновения, но&nbsp;и&nbsp;от&nbsp;утечек газа, пожаров и&nbsp;протечек воды. Обилие датчиков позволяет не&nbsp;только контролировать, но&nbsp;и&nbsp;предотвращать аварийные ситуации.</p>'
    },
  ]
  return (
      <Accordion  config={config}/>
  )
}